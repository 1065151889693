import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Seo } from 'gatsby-plugin-wpgraphql-seo'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Appointment from '../components/appointment'
import Copyright from '../components/copyright'
import Layout from '../components/layout'
import Recommendation from '../components/recommendation'
import onClick from '../utils/vendor-onclick'

class IndexPage extends Component {
  render() {
    const data = this.props.data

    if (data.wpPage.vendors.vendors.vendorSub) {
      document.getElementById('vendor1').classList.add('vendor-info')
      document.getElementById('vendor2').classList.add('vendor-info')
      document.getElementById('vendor3').classList.add('vendor-info')
    }

    return (
      <Layout>
        <Helmet htmlAttributes={{ lang: 'de' }}>
          <html lang="de" />
          {/* Global site tag (gtag.js) - Google Analytics */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-88175334-10"
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-88175334-10');`}
          </script>
          <script type="text/javascript">
            {`window.smartlook||(function(d) {
                var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
              })(document);
              smartlook('init', '061bf20bcfc1cd66c3fa368ec42c78a965c61699');`}
          </script>
        </Helmet>
        {/* <SEO
          lang="de"
          title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
          description={data.wordpressPage.yoast_meta.yoast_wpseo_.metadesc}
        /> */}
        <Seo post={data.wpPage} />
        <Appointment vendors={data.wpPage.vendors.vendors} />

        <section id="headerimg">
          <GatsbyImage
            image={
              data.wpPage.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData
            }
            alt="Image description"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
          />
          <section id="banner">
            <header>
              <h1>{data.wpPage.title}</h1>
              <p className="headerimgtext">
                Ihr umfassender Ratgeber zur Wartung von Thermen.
              </p>
            </header>
          </section>
        </section>
        <section id="headersymb">
          <section id="intro" class="container">
            <div class="row">
              <div id="vendor1" class="col-4 col-12-medium">
                <section class="first">
                  <i class="icon solid featured">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="72"
                      height="72"
                      fill="white"
                    >
                      <defs />
                      <path d="M507.7 109.1a12 12 0 00-20-5.5l-74.4 74.3-68-11.3-11.2-67.8 74.3-74.4a12 12 0 00-5.6-20.2 144 144 0 00-136.6 38 144 144 0 00-34 147.2L18.6 402.8a64 64 0 0090.5 90.5L322.5 280a143.5 143.5 0 00185.2-170.9zM64 472a24 24 0 110-48 24 24 0 010 48z" />
                    </svg>
                  </i>
                  {data.wpPage.vendors.vendors[0] ? (
                    <>
                      <header>
                        <h2>{data.wpPage.vendors.vendors[0].vendorName}</h2>

                        <strong>
                          {data.wpPage.vendors.vendors[0].vendorSub}
                        </strong>
                      </header>

                      <p>
                        <b>{data.wpPage.vendors.vendors[0].vendorPrice}</b>
                      </p>

                      <footer>
                        <ul class="actions">
                          <li>
                            <label
                              for="modal"
                              href="#"
                              role="button"
                              onClick={() =>
                                onClick(
                                  data.wpPage.vendors.vendors[0].vendorName
                                )
                              }
                            >
                              Terminvereinbarung
                            </label>
                          </li>
                        </ul>
                      </footer>
                    </>
                  ) : (
                    <header>
                      <h2>Coming soon</h2>
                    </header>
                  )}
                </section>
              </div>
              <div id="vendor2" class="col-4 col-12-medium">
                <section class="middle">
                  <i class="icon solid featured alt">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="72"
                      height="72"
                      fill="white"
                    >
                      <defs />
                      <path d="M502.6 214.6l-45.2-45.2a32 32 0 00-22.6-9.4H384V80a48 48 0 00-48-48H176a48 48 0 00-48 48v80H77.2a32 32 0 00-22.6 9.4L9.4 214.6A32 32 0 000 237.3V320h128v-16a16 16 0 0116-16h32a16 16 0 0116 16v16h128v-16a16 16 0 0116-16h32a16 16 0 0116 16v16h128v-82.8a32 32 0 00-9.4-22.6zM320 160H192V96h128v64zm64 208a16 16 0 01-16 16h-32a16 16 0 01-16-16v-16H192v16a16 16 0 01-16 16h-32a16 16 0 01-16-16v-16H0v96a32 32 0 0032 32h448a32 32 0 0032-32v-96H384v16z" />
                    </svg>
                  </i>
                  {data.wpPage.vendors.vendors[1] ? (
                    <>
                      <header>
                        <h2>{data.wpPage.vendors.vendors[1].vendorName}</h2>

                        <strong>
                          {data.wpPage.vendors.vendors[1].vendorSub}
                        </strong>
                      </header>

                      <p>
                        <b>{data.wpPage.vendors.vendors[1].vendorPrice}</b>
                      </p>

                      <footer>
                        <ul class="actions">
                          <li>
                            <label
                              for="modal"
                              href="#"
                              role="button"
                              class="alt"
                              onClick={() =>
                                onClick(
                                  data.wpPage.vendors.vendors[1].vendorName
                                )
                              }
                            >
                              Terminvereinbarung
                            </label>
                          </li>
                        </ul>
                      </footer>
                    </>
                  ) : (
                    <header>
                      <h2>Coming soon</h2>
                    </header>
                  )}
                </section>
              </div>
              <div id="vendor3" class="col-4 col-12-medium">
                <section class="last">
                  <i class="icon solid featured alt2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      height="72"
                      width="72"
                      fill="white"
                    >
                      <defs />
                      <path d="M352 256h-38.6a132.9 132.9 0 00-57.4-28v-47.6l-32-3.3-32 3.3V228c-22 5-41.7 14.6-57.4 28H16a16 16 0 00-16 16v96a16 16 0 0016 16h92.8c20.6 37.7 64.2 64 115.2 64s94.6-26.3 115.2-64H352a32 32 0 0132 32 32 32 0 0032 32h64a32 32 0 0032-32 160 160 0 00-160-160zM81.6 160L224 145l142.4 15c9.4 1 17.6-6.9 17.6-16.9V113c0-10-8.2-17.8-17.6-16.8L256 107.7V80a16 16 0 00-16-16h-32a16 16 0 00-16 16v27.7L81.6 96.1c-9.4-1-17.6 6.8-17.6 16.8V143c0 10 8.2 17.8 17.6 16.8z" />
                    </svg>
                  </i>

                  {data.wpPage.vendors.vendors[2] ? (
                    <>
                      <header>
                        <h2>{data.wpPage.vendors.vendors[2].vendorName}</h2>

                        <strong>
                          {data.wpPage.vendors.vendors[2].vendorSub}
                        </strong>
                      </header>

                      <p>
                        <b>{data.wpPage.vendors.vendors[2].vendorPrice}</b>
                      </p>

                      <footer>
                        <ul class="actions">
                          <li>
                            <label
                              for="modal"
                              href="#"
                              role="button"
                              class="alt2"
                              onClick={() =>
                                onClick(
                                  data.wpPage.vendors.vendors[2].vendorName
                                )
                              }
                            >
                              Terminvereinbarung
                            </label>
                          </li>
                        </ul>
                      </footer>
                    </>
                  ) : (
                    <header>
                      <h2>Coming soon</h2>
                    </header>
                  )}
                </section>
              </div>
            </div>
          </section>
        </section>

        <section id="main">
          <div class="row">
            <section>
              <main
                dangerouslySetInnerHTML={{
                  __html: data.wpPage.content,
                }}
              />
            </section>
          </div>
        </section>
        <footer id="footer">
          <div class="container">
            <section>
              <header>
                <h2>Thermenwartung Wien Preisvergleich</h2>
              </header>

              <Recommendation vendors={data.wpPage.vendors.vendors} />
            </section>
            <Copyright />
          </div>
        </footer>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDo2" }) {
      title
      content
      vendors {
        vendors {
          vendorSub
          vendorPrice
          vendorName
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

export default IndexPage
