import React, { Component } from 'react'
import onClick from '../utils/vendor-onclick'

// TODO: code cleanup!
class Recommendation extends Component {
  render() {
    return (
      <div>
        <ul class="numberfieldfooter">
          {this.props.vendors.map(vendor => (
            <div class="row">
              <div class="col-12">
                <li className="li--footer">
                  <div class="row aln-middle">
                    <div class="col-12-small col-7-medium col-5-large col-4">
                      <span class="spanforvendornumbers">
                        <strong className="vendornumber"></strong>
                      </span>
                      <div class="div-big-height">
                        <h3 className="h3--footer">{vendor.vendorName}</h3>
                        <small>{vendor.vendorSub}</small>
                      </div>
                      <div class="div-big-height col-12-small col-6-medium col-3-large col-2 ">
                        {vendor.vendorPrice}
                      </div>
                    </div>

                    <div class="col-12-large col-4">
                      <ul className="actions">
                        <li className="li--buttons">
                          <a
                            href="tel:+43-677-631-056-79"
                            className="button button--round"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="white"
                            >
                              <defs />
                              <path fill="none" d="M0 0h24v24H0V0z" />
                              <path d="M19.23 15.26l-2.54-.29a1.99 1.99 0 00-1.64.57l-1.84 1.84a15.045 15.045 0 01-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52a2.001 2.001 0 00-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
                            </svg>
                          </a>
                        </li>
                        <li className="li--buttons">
                          <label
                            for="modal"
                            role="button"
                            onClick={() => onClick(vendor.vendorName)}
                          >
                            Terminvereinbarung
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          ))}
        </ul>
        <p>
          <i>
            Preise inklusive Anfahrtszeit, Arbeitszeit, MwSt., exklusive
            Geräteersatzteile
          </i>
        </p>
        <br />
      </div>
    )
  }
}

export default Recommendation
