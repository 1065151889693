export default name => {
  // document.getElementyById(vendor.vendorName + '-option').selected = 'selected'

  document.getElementById('selector').value = name
  let e = document.getElementsByClassName('vendor-info--active')

  for (let i = 0; i < e.length; i++) {
    e[i].classList.remove('vendor-info--active')
  }

  document.getElementById(name + '-div').classList.add('vendor-info--active')
}
